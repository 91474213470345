import React, { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import AgentFilterDropdown from "../AgentFilterDropdown";

const Chart = ({ jobDetails }) => {

  const [reviews, setReviews] = useState([]);
  const [mode, setMode] = useState("week"); 
  const [agentName, setAgentName] = useState('all');
  

  useEffect(()=>{
    (async () => {
    
      try {
        const response = await fetch(`/app-reviews?agent_name=${agentName}`, {
          method: "GET",
          credentials: "include", // Include cookies in the request
        });
    
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
    
        const reviewData = await response.json();
        setReviews(reviewData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();}
    ,[agentName]);

  const handleSelectChange = useCallback(
    (value) => {
      setAgentName(value);},
    [],
  );

  const handleButtonClick = (newMode) => {
    if (newMode !== mode) {
      setMode(newMode);
      renderReviewCharts(reviews, mode);
    }
  };

  const getChartData = (e, mode) => {
    const weeklyReviews = e?.weekly_reviews || [];
    const monthlyReviews = e?.monthly_reviews || [];

    let labels = [];
    let data = [];

    if (mode === "month") {
      labels = monthlyReviews.map((e) => convertToShortMonthName(e.month));
      data = monthlyReviews.map((e) => e.review_count);
    } else {
      labels = weeklyReviews.map((e) => e.week);
      data = weeklyReviews.map((e) => e.review_count);
    }

    return { labels, data };
  };

  const renderReviewCharts = useCallback((reviews, mode) => {
    if (reviews?.length) {
      return reviews.map((e) => {
        const app_code = e?.app_code;
        const data = getChartData(e, mode);

        return (
          <div
            id={app_code}
            key={`${app_code}-reviews`}
            style={{ width: "100%", maxWidth: "420px" }}
          >
            <Bar
              id={app_code}
              data={{
                labels: data.labels,
                datasets: [
                  {
                    label: "# Reviews" ,
                    data: data.data,
                    backgroundColor: Array(12).fill("#288FFF"),
                    borderColor: Array(12).fill("rgb(255, 99, 132)"),
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: convertToUpperCase(app_code),
                    position: "top",
                    align: "center",
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    suggestedMax: Math.max(...data.data) + 2, // Add padding
                    precision: 0,
                  },
                },
              }}
              style={{ width: "100%", height: "400px", maxWidth: "420px" }}
            />
          </div>
        );
      });
    }
    return null;
  }, []);

  const renderJobDetails = (jobDetails) => {
    if (!jobDetails || !jobDetails.length) return null;
    const job = jobDetails[0]; // Taking the first job details for rendering

    return (
      <>
        <div className="flex-box">
          {/* Waiting Jobs */}
          <WaitingJobCountChart job={job} appCode={job.app_code} />

          {/* Running Jobs */}
          <RunningJobCountChart job={job} appCode={job.app_code} />
        </div>

        <div
          style={{
            width: "100%",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            backgroundColor: "rgb(255, 255, 255)",
          }}
        >
          {/* Pending Jobs */}
          <JobDetailChart
            title="Waiting Jobs"
            labels={job.all_jobs.map((e) => e.true_queue)}
            data={job.all_jobs.map((e) => e.count)}
            appCode={job.app_code}
            chartId="pending-jobs"
            backgroundColor="#288FFF"
          />

          {/* Failed Jobs */}
          <JobDetailChart
            title="Failed Jobs"
            labels={job.failed_jobs.map((e) => e.true_queue)}
            data={job.failed_jobs.map((e) => e.count)}
            appCode={job.app_code}
            chartId="failed-jobs"
            backgroundColor="#dc3545"
          />
        </div>
      </>
    );
  };

  return (
    <div>
         {reviews && reviews.length > 0 && jobDetails && jobDetails.length > 0 && (
        <>
          <div className="toggle-container">
            <div style={{marginTop: '5px', marginRight: '5px'}}>
              <AgentFilterDropdown agentName={agentName} handleSelectChange={handleSelectChange} />
            </div>
            <div className="button-group">
              <button
                className={`button button-weekly ${
                  mode === "week" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("week")}
              >
                Weekly
              </button>
              <button
                className={`button button-monthly ${
                  mode === "month" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("month")}
              >
                Monthly
              </button>
            </div>
          </div>

          <div className="canvas-grid">
            <div
              className="canvas-container"
              id="canvas-container"
              style={{ gap: "40px" }}
            >
              {renderReviewCharts(reviews, mode)}
            </div>
          </div>

          <div className="job-detail-container">
            {renderJobDetails(jobDetails)}
          </div>
        </>
              )}
    </div>
  );
};

function JobDetailChart({
  title,
  labels,
  data,
  appCode,
  chartId,
  backgroundColor,
}) {
  const chartData = {
    labels: labels.slice(0, 25),
    datasets: [
      {
        label: title,
        data: data.slice(0, 25),
        backgroundColor: Array(25).fill(backgroundColor),
        borderColor: Array(25).fill(backgroundColor),
      },
    ],
  };

  return (
    <div
      className="job-detail-canvas"
      id={`${chartId}-detail-canvas`}
      style={{
        width: `${Math.max(50 * labels.length, 800)}px`,
        maxWidth: "calc(100vw - 300px)",
        overflowX: scroll,
      }}
    >
      <Bar
        key={`${appCode}_${chartId}`}
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: convertToUpperCase(appCode),
              position: "top",
              align: "center",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              suggestedMax: Math.max(...data) + 2,
              precision: 0,
            },
          },
        }}
        style={{ width: "100%", height: "auto", maxHeight: "560px" }}
      />
    </div>
  );
}

function WaitingJobCountChart({ job, appCode }) {
  const dataMap = {
    count1: "0-5 min",
    count2: "5-10 min",
    count3: "10-15 min",
    count4: "15-20 min",
    count5: "20-25 min",
    count6: "25-30 min",
    count7: "30-35 min",
  };

  const labels = [];
  const data = [];

  job?.job_count?.forEach((obj) => {
    // Access each object's properties
    Object.keys(obj).forEach((key) => {
      data.push(obj[key]);
      labels.push(dataMap[key]);
    });
  });

  return (
    <div
      className="flex-child"
      id={`${appCode}_waiting_jobs`}
      style={{ width: "100%", margin: "20px 0" }}
    >
      <Bar
        id={appCode + "job_count"}
        key={`${appCode}_waiting_jobs`}
        data={{
          labels,
          datasets: [
            {
              label: "Waiting Jobs",
              data,
              backgroundColor: Array(labels.length).fill("#808080"),
              borderColor: Array(labels.length).fill("#808080"),
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: convertToUpperCase(appCode),
              position: "top",
              align: "center",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              suggestedMax: Math.max(...data) + 2,
              precision: 0,
            },
          },
        }}
        style={{ width: "100%", height: "300px" }}
      />
    </div>
  );
}

function RunningJobCountChart({ job, appCode }) {
  return (
    <div
      className="flex-child"
      id={`${appCode}_running_jobs`}
      style={{ width: "100%", margin: "20px 0" }}
    >
      <b>Running Jobs:</b>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.25rem",
          marginTop: "0.5rem",
        }}
      >
        {job?.locked_jobs?.map((locked_job, idx) => (
          <span
            key={idx}
            className="badge badge-success"
            data-placement="top"
            data-toggle="tooltip"
            data-mdb-custom-class="text-sm"
          >
            {locked_job.queue}
          </span>
        ))}
      </div>
    </div>
  );
}

// Utility Functions
function convertToShortMonthName(dateString) {
  const date = new Date(`${dateString}-01`); // Appending '-01' to ensure it's the first day of the month
  const options = { month: "short", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

function convertToUpperCase(str) {
  const words = str.split("_");
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export default Chart;
