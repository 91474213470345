import {Select} from '@shopify/polaris';
import React from 'react';
import { supportAgentNames } from '../data';

export default function AgentFilterDropdown({ agentName, handleSelectChange }) {

  return (
    <Select
      label="Agent: "
      options={[{label: 'All reviews', value: 'all'}, ...supportAgentNames]}
      onChange={handleSelectChange}
      value={agentName}
      labelInline
    />
  );
}